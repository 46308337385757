import React, { useEffect, useState } from 'react';
import './index.css'; // Import the custom CSS
import './geo.svg'; // Import the SVG icon

function App() {
  const [ipv4, setIpv4] = useState('Fetching IPv4 address...');
  const [latLong, setLatLong] = useState('');
  const [copyLatLong, setCopyLatLong] = useState('');

  useEffect(() => {
    // Set initial theme based on localStorage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.documentElement.classList.add(savedTheme);
    } else {
      // Default to dark theme if no saved theme
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
    fetchIpAddress();
  }, []);

  const fetchIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      if (!response.ok) throw new Error('Failed to fetch IPv4 address');
      const data = await response.json();
      setIpv4(`IPv4: ${data.ip}`);
    } catch (error) {
      console.error('Error:', error);
      setIpv4('Failed to fetch IPv4 address');
    }
  };

  const convertToLatLong = async () => {
    const ip = document.getElementById('ipInput').value.trim();
    if (!ip) {
      alert('Please enter a valid IP address');
      return;
    }

    try {
      const response = await fetch(`https://ipinfo.io/${ip}/json`);
      const data = await response.json();
      if (response.ok && data.loc) {
        const [lat, lon] = data.loc.split(',');
        setLatLong(` ${lat}, ${lon}`);
        setCopyLatLong(`${lat}, ${lon}`);
      } else {
        setLatLong(`Error: ${data.reason || 'Unknown error'}`);
        setCopyLatLong('');
      }
    } catch (error) {
      console.error('Error:', error);
      setLatLong('An error occurred while fetching location data');
      setCopyLatLong('');
    }
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert(`Copied to clipboard: ${text}`);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
          // Fallback: Use a temporary textarea to copy text
          const textarea = document.createElement('textarea');
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          alert(`Copied to clipboard (fallback): ${text}`);
        });
    } else {
      // Fallback: Use a temporary textarea to copy text
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      alert(`Copied to clipboard (fallback): ${text}`);
    }
  };

  const pasteFromClipboard = async (inputId) => {
    if (navigator.clipboard) {
      try {
        const text = await navigator.clipboard.readText();
        document.getElementById(inputId).value = text;
      } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
      }
    } else {
      // Fallback: Use file input to simulate clipboard paste
      const input = document.createElement('input');
      input.type = 'file';
      input.style.display = 'none';
      document.body.appendChild(input);
      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            document.getElementById(inputId).value = e.target.result;
            document.body.removeChild(input);
          };
          reader.readAsText(file);
        }
      });
      input.click();
    }
  };

  const showOnMap = () => {
    const latLongInput = document.getElementById('latlongInput').value;
    const mapFrame = document.getElementById('mapFrame');
    if (latLongInput) {
      const [lat, lon] = latLongInput.split(',');
      mapFrame.src = `https://www.google.com/maps?q=${lat.trim()},${lon.trim()}&hl=en&z=15&output=embed`;
      mapFrame.style.display = 'block';
    } else {
      alert('Please enter latitude and longitude.');
    }
  };

  const toggleDarkMode = () => {
    // Check the current theme and toggle it
    const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';

    // Apply the new theme
    document.documentElement.classList.toggle('dark', newTheme === 'dark');
    
    // Save the new theme to localStorage
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div className="geo-bg w-full h-full flex flex-col justify-center items-center h-screen bg-gray-100 dark:bg-gray-900 p-4 m-0 box-border transition-colors duration-300">
      <div className="container mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full">
        <button
          className="mb-4 px-4 py-2 text-sm text-white bg-gray-800 dark:bg-gray-400 rounded hover:bg-gray-600 dark:hover:bg-gray-600"
          onClick={toggleDarkMode}
        >
         Dark Mode
        </button>
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-gray-800 dark:text-gray-100">IP Address Map Tool</h1>
          <img src="geo.svg" alt="Geo Icon" className="w-8 h-8" />
        </div>
        <div className="ip-container mb-4 flex items-center justify-center">
          <div id="ipv4" className="text-gray-800 dark:text-gray-200 text-lg mr-2">{ipv4}</div>
          <button className="px-3 py-1 text-sm text-white bg-blue-600 rounded hover:bg-blue-700" onClick={() => copyToClipboard(ipv4.replace('IPv4: ', ''))}>Copy</button>
        </div>

        <div className="latlong-container mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-100">Latitude and Longitude</h2>
          <input type="text" id="ipInput" className="w-full p-2 text-sm border border-gray-300 dark:border-gray-600 rounded mb-2 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100" placeholder="192.168.0.1" />
          <button className="px-4 py-2 text-sm text-white bg-blue-600 rounded hover:bg-blue-700 mr-2" onClick={convertToLatLong}>Convert</button>
          <button className="px-4 py-2 text-sm text-white bg-slate-500 rounded hover:bg-slate-600" onClick={() => pasteFromClipboard('ipInput')}>Paste</button>
          <div id="latlongResult" className="mt-4 text-gray-800 dark:text-gray-200 text-sm">{latLong}</div>
          {copyLatLong && <button className="px-3 py-1 text-sm text-white bg-green-500 rounded hover:bg-green-600 mt-2" id="copyLatLongBtn" onClick={() => copyToClipboard(copyLatLong)}>Copy</button>}
        </div>

        <div className="latlong-container mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-100">Show Coordinates on Map</h2>
          <input type="text" id="latlongInput" className="w-full p-2 text-sm border border-gray-300 dark:border-gray-600 rounded mb-2 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100" placeholder="Latitude, Longitude" />
          <button className="px-4 py-2 text-sm text-white bg-blue-600 rounded hover:bg-blue-700 mr-2" onClick={showOnMap}>Show on Map</button>
          <iframe id="mapFrame" className="w-full h-60 mt-4 border-none rounded" style={{ display: 'none' }}></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
